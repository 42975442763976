import { AppRegistration, Home, Logout, Menu, Person } from '@mui/icons-material'
import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, MenuItem, Select, type SelectChangeEvent, Toolbar, Typography, InputLabel, FormControl } from '@mui/material'
import React, { type ReactNode, useMemo, useState, useEffect, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { logOut } from 'utils/auth'
import { type League } from 'utils/constants/constants'
import { useAuthState } from 'utils/hooks/useAuthState'
import { useActiveLeague } from 'utils/hooks/useActiveLeague'
import { usePageTitle } from 'utils/hooks/usePageTitle'
import Footer from 'components/common/Footer'

import styles from 'components/common/navigation/DrawerNavigation.module.scss'
import { DrawerMenuItem } from './DrawerMenuItem'

const drawerWidth = 240

interface DrawerNavigationProps {
  children?: ReactNode
  menu?: ReactNode
  onChange?: (open: boolean) => void
}

function scrollToTop () {
  window.scrollTo(0, 0)
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

const DrawerNavigation: React.FC<DrawerNavigationProps> = ({ children, menu, onChange }) => {
  const [open, setOpen] = useState(false)
  const [title] = usePageTitle()
  const { user } = useAuthState()
  const navigate = useNavigate()
  const [activeLeague, setActiveLeague] = useActiveLeague()
  const [, setSearchParams] = useSearchParams()

  const handleLeagueChange = useCallback((event: SelectChangeEvent<string>): void => {
    setActiveLeague(event.target.value as League)
    scrollToTop()
    setSearchParams({})
    navigate(`/${event.target.value}/home`)
    handleDrawerClose()
  }, [navigate, setActiveLeague, setSearchParams])

  useEffect(() => {
    onChange?.(open)
  }, [onChange, open])

  function handleDrawerOpen () {
    setOpen(true)
  }

  function handleDrawerClose () {
    setOpen(false)
  }

  const drawer = useMemo(() => (
    <div>
      <Toolbar>
        <img className={styles.logo} alt='RefReport logo' src='/static/logo-black-transparent.webp' />
      </Toolbar>
      <Divider />
      <List style={{ paddingBottom: 0 }}>
        <Typography variant='overline' sx={{ paddingLeft: 2, paddingTop: 2 }}>League</Typography>
        {(user?.leagues?.length ?? 0) > 0 && (
          <ListItem>
            <FormControl fullWidth>
              <InputLabel>Choose League</InputLabel>
              <Select
                label='Choose League'
                onChange={handleLeagueChange}
                placeholder='Choose League '
                fullWidth
                value={activeLeague}
              >
                {activeLeague && <MenuItem value={activeLeague}>{activeLeague.toUpperCase()}</MenuItem>}
                {user?.leagues.filter(l => l !== activeLeague).map((l) => <MenuItem key={l} value={l}>{l.toUpperCase()}</MenuItem>)}
              </Select>
            </FormControl>
          </ListItem>
        )}
        {activeLeague && <DrawerMenuItem league={activeLeague} path='home' icon={<Home />}>League Home</DrawerMenuItem>}
      </List>
      {menu}
      <Divider />
      <List>
        <Typography variant='overline' sx={{ paddingLeft: 2, paddingTop: 2 }}>Account</Typography>
        <ListItem>
          <ListItemButton onClick={() => { navigate('/account') }}><ListItemIcon><Person /></ListItemIcon> Account settings</ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => { navigate('/auth/join-league') }}><ListItemIcon><AppRegistration /></ListItemIcon> Join a League</ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => { void logOut() }}><ListItemIcon><Logout /></ListItemIcon> Sign Out</ListItemButton>
        </ListItem>
      </List>
    </div>
  ), [handleLeagueChange, activeLeague, user?.leagues, menu, navigate])

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
    >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={{ mr: 2, display: { sm: 'none' } }}
        >
            <Menu />
          </IconButton>
          <Typography variant="h6" component="div">{title}</Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="navigation menu"
    >
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
      >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
          open
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true
          }}
      >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginLeft: { sm: `${drawerWidth}px` },
          minHeight: '80vh'
        }}
    >
        <Toolbar />
        {children}
      </Box>
      <Footer />
    </Box>
  )
}

export default DrawerNavigation
