import { Add, Assessment, Description, People, UploadFile } from '@mui/icons-material'
import { Divider, List, Typography } from '@mui/material'
import { DrawerMenuItem } from 'components/common/navigation/DrawerMenuItem'
import React, { useMemo } from 'react'
import { League } from 'utils/constants/constants'
import { useAuthState } from 'utils/hooks/useAuthState'
import { Permission } from 'utils/providers/AuthProvider'

interface MASLNavigationMenuProps {
  league: League
}

const MASLNavigationMenu: React.FC<MASLNavigationMenuProps> = ({ league }) => {
  const { user } = useAuthState()
  const isAdmin = useMemo(() => league && user?.permissions[league].includes(Permission.ADMIN), [league, user?.permissions])

  return (
    <>
      <List style={{ paddingTop: 0 }}>
        <DrawerMenuItem league={league} path='documents' icon={<Description />}>Documents</DrawerMenuItem>
      </List>
      {isAdmin && <Divider />}
      {isAdmin && (
        <>
          {league === League.MASL && (
          <>
            <List>
              <Typography variant='overline' sx={{ paddingLeft: 2, paddingTop: 2 }}>Tools</Typography>
              <DrawerMenuItem league={league} path='export' icon={<Assessment />}>Penalty report</DrawerMenuItem>
            </List>
            <Divider />
          </>
          )}
          <List>
            <Typography variant='overline' sx={{ paddingLeft: 2, paddingTop: 2 }}>Admin</Typography>
            <DrawerMenuItem league={league} path='create' icon={<Add />}>Create Game</DrawerMenuItem>
            <DrawerMenuItem league={league} path='manage-user' icon={<People />}>Members</DrawerMenuItem>
            <DrawerMenuItem league={league} path='import' icon={<UploadFile />}>Import Schedule</DrawerMenuItem>
            {league === League.MASL && (
              <>
                <DrawerMenuItem league={league} path='upload-player-list' icon={<UploadFile />}>Upload players</DrawerMenuItem>
              </>
            )}
          </List>
        </>
      )}
    </>
  )
}
export default MASLNavigationMenu
