import { parse } from 'papaparse'

function position <T> (header: keyof T, headerRow: Array<keyof T>) {
  return headerRow.indexOf(header)
}

export async function readFile<T> (
  file: File | null | undefined,
  headerRow: Array<keyof T>
): Promise<{ results: Array<Partial<T>>, errors: ParseError[] }> {
  const { data, errors } = await parseFile(file)
  const rows = data.slice(1)

  const results = []

  for (const row of rows) {
    try {
      if (row.every(item => item === '')) continue
      const report: Partial<T> = Object.fromEntries(headerRow.map((data) => ([data, row[position<T>(data, headerRow)]]))) as Partial<T>
      results.push(report)
    } catch (e: unknown) {
      console.error(`${e as string} on ${row.toString()}`)
      continue
    }
  }

  return { results, errors }
}

interface ParseError {
  type: string
  code: string
  message: string
  row: number
}

interface ParseMeta {
  delimiter: string
  linebreak: string
  aborted: boolean
  fields: string[]
  truncated: boolean
}

interface ParseResult {
  data: string[][]
  errors: ParseError[]
  meta: ParseMeta
}

async function parseFile (file: File | null | undefined): Promise<ParseResult> {
  return await new Promise((resolve, reject) => {
    if (!file) {
      reject(new TypeError('File cannot be null'))
      return
    }

    parse(file, {
      complete: (results) => {
        resolve(results as ParseResult)
      },
      error: (e) => {
        reject(e)
      }
    })
  })
}
