import React, { useState } from 'react'
import { type Dayjs } from 'dayjs'
import { CircularProgress, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { unparse } from 'papaparse'

import { BasicDatePicker, Section } from 'components/common'
import { getMASLPenalties } from 'utils/api/masl-api'
import { League } from 'utils/constants/constants'
import { useApi } from 'utils/hooks/useApi'
import { MASLPlayerType } from 'utils/constants/masl'

import styles from 'components/masl/MASLExport.module.scss'

export const MASLExport: React.FC = () => {
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [exportLoading, setExportLoading] = useState(false)

  const { data, loading } = useApi(async () => {
    if (!startDate || !endDate) {
      return { penalties: undefined }
    }
    return await getMASLPenalties(League.MASL, startDate, endDate)
  }, [startDate, endDate])

  function exportToCSV () {
    if (!data?.penalties) {
      return
    }
    setExportLoading(true)
    const csv = unparse(data?.penalties?.map(penalty => {
      const name = penalty.playerType === MASLPlayerType.Team ? 'Team' : penalty.name
      const [lastName, firstName] = name?.includes(',') ? name.split(',') : [name, name]
      return {
        Team: penalty.team,
        'Last Name': lastName?.trim() ?? '',
        'First Name': firstName?.trim() ?? '',
        'Game Number': penalty.gameNumber,
        Date: penalty.date,
        Code: penalty.code,
        Fine: penalty?.fine ?? '',
        Explanation: penalty.explanation,
        'Point(s)': penalty.points
      }
    }))
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const csvURL = window.URL.createObjectURL(csvData)

    const tempLink = document.createElement('a')
    tempLink.href = csvURL
    tempLink.setAttribute('download', `penalty-data-${startDate ?? ''}-to-${endDate ?? ''}-exported-${new Date().toLocaleTimeString()}.csv`)
    tempLink.click()
    setExportLoading(false)
  }

  return (
    <>
      <Section style={{ marginTop: 16 }}>
        <BasicDatePicker
          label='Start date'
          onChange={(value, context) => {
            setStartDate((value as Dayjs).toDate().toDateString())
          }}
        />
        <BasicDatePicker
          label='End date'
          onChange={(value, context) => {
            setEndDate((value as Dayjs).toDate().toDateString())
          }}
        />
        <Divider orientation='vertical' flexItem />
        <LoadingButton
          onClick={exportToCSV}
          loading={exportLoading}
          variant='contained'
          disabled={!data?.penalties?.length || exportLoading}
        >Export to CSV</LoadingButton>
      </Section>

      <Divider />
      {loading && (
        <section className={styles.spinnerContainer}>
          <CircularProgress size={80} />
        </section>
      )}
      <section style={{ marginTop: 16 }}>
        {data?.penalties && (
          <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Team</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Game Number</TableCell>
                  <TableCell align="left">Game Date</TableCell>
                  <TableCell align="left">Code</TableCell>
                  <TableCell align="left">Fine</TableCell>
                  <TableCell align="left">Explanation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.penalties?.map((penalty) => (
                  <TableRow
                    key={penalty.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">{penalty.team}</TableCell>
                    <TableCell align="left">{penalty.name?.replace(/ *\([^)]*\) */g, '').trim()}</TableCell>
                    <TableCell align="left">{penalty.gameNumber ?? ''}</TableCell>
                    <TableCell align='left'>{penalty.date && (penalty.date.length > 0) && new Date(penalty?.date ?? '').toLocaleDateString()}</TableCell>
                    <TableCell align="left">{penalty.code}</TableCell>
                    <TableCell align="left">{penalty?.fine}</TableCell>
                    <TableCell align="left">{penalty.explanation}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </section>
    </>
  )
}
