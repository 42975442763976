import { post } from 'utils/api/api'
import { useApi } from 'utils/hooks/useApi'

async function getLeagueSignUpCode (league: string) {
  const response = await post<string>(
    'https://getleaguesignupcode-jdkif3ehcq-uc.a.run.app',
    { league }
  )
  return response
}

export const useGetLeagueSignUpCode = (league: string) => {
  const { data, loading, error } = useApi(async () => {
    if (!league) {
      return undefined
    }
    return await getLeagueSignUpCode(league)
  }, [league])

  return { data, loading, error }
}
