import React, { useMemo } from 'react'

import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material'
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import GroupIcon from '@mui/icons-material/Group'

import TopNavigation from 'components/common/navigation/TopNavigation'
import PageTitle from 'components/common/PageTitle'

const Index = () => {
  const SignInButton = useMemo(() => (
    <Box display='flex' gap='0.5em'>
      <Button
        href='/auth/sign-in'
        color='secondary'
        variant='contained'
      >
        Sign in
      </Button>
    </Box>
  ), [])

  return (
    <>
      <PageTitle title='Digital match reporting' />
      <TopNavigation buttons={[SignInButton]}>
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box
            sx={{
              textAlign: 'center',
              my: 6,
              p: 6,
              bgcolor: 'primary.light',
              borderRadius: 2,
              boxShadow: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
        >
            <Typography variant="h2" component="h1" gutterBottom>
              Welcome to RefReport
            </Typography>
            <Typography variant="h5" color="textSecondary" paragraph>
              The fastest, easiest, and most reliable way for sports officials to submit match reports.
            </Typography>
            <Box display={'flex'} alignItems={'center'} gap={1} margin={'auto'}>
              <Button href='/auth/sign-in' variant="contained" size="large" color="secondary">
                Sign in
              </Button>
              <Divider flexItem orientation='vertical' />
              <Button href='/auth/sign-up' variant="text" size="large" color="secondary">
                Get Started
              </Button>
              <Divider flexItem orientation='vertical' />
              <Button href='/faq' variant="text" size="large" color="secondary">
                Learn More
              </Button>
            </Box>
          </Box>

          {/* Features Section */}
          <Box sx={{ my: 8 }}>
            <Typography variant="h4" align="center" gutterBottom>
              Why RefReport?
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={4}>
                <Card sx={{ textAlign: 'center', p: 3 }}>
                  <SportsSoccerIcon sx={{ fontSize: 60, color: 'primary' }} />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Seamless Reporting
                    </Typography>
                    <Typography color="textSecondary">
                      Submit match reports in just a few clicks with our easy-to-use digital form system.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card sx={{ textAlign: 'center', p: 3 }}>
                  <CheckCircleOutlineIcon sx={{ fontSize: 60, color: 'primary' }} />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Accurate & Secure
                    </Typography>
                    <Typography color="textSecondary">
                      Your reports are safely stored and shared with the right people, ensuring accuracy and privacy.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card sx={{ textAlign: 'center', p: 3 }}>
                  <GroupIcon sx={{ fontSize: 60, color: 'primary' }} />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Built for Officials
                    </Typography>
                    <Typography color="textSecondary">
                      Designed by sports professionals, RefReport simplifies your workflow so you can focus on the game.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          {/* Partnership Section */}
          <Box sx={{ my: 8, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
              Partners:
            </Typography>
            <Box
              sx={{
                bgcolor: 'black',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
                flexDirection: 'column'
              }}
          >
              <img src="https://digitalshift-assets.sfo2.cdn.digitaloceanspaces.com/pw/52a4447e-3bb2-40af-b051-2d68f227347e/logo-1634078726047248475.svg" alt="MASL Logo" style={{ maxHeight: '40px', margin: '20px auto' }} />
              <img src="https://digitalshift-assets.sfo2.cdn.digitaloceanspaces.com/pw/1cbd28e2-c81d-4542-b401-2504615000cd/logo-1725379630337488885.png" alt="Major Arena Soccer League 2" style={{ maxHeight: '40px', margin: '20px auto' }} />
            </Box>
            <Typography variant="body1" color="textSecondary" paragraph>
              RefReport is trusted by top leagues like the MASL to provide seamless and accurate digital match reporting.
            </Typography>
          </Box>

          {/* How It Works Section */}
          {/* Verbal Description of Product */}
          <Box sx={{ my: 8 }}>
            <Typography variant="h4" align="center" gutterBottom>
              The Ultimate Solution for Sports Leagues
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              RefReport is a comprehensive digital platform designed specifically for sports officials, allowing them to submit match reports quickly, accurately, and securely. With RefReport, there&apos;s no need to deal with messy paperwork or outdated systems. Our intuitive online interface is built with simplicity in mind, enabling referees to enter critical match details with ease from any device—whether they’re in the stadium or on the go.
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Not only does RefReport save you time, but it also guarantees that your reports reach the appropriate league administrators in real time, ensuring compliance and transparency. Whether it’s entering game scores, player infractions, or technical incidents, the platform streamlines the entire process and minimizes errors. Each report is securely stored and easily accessible for future reference, so you never have to worry about lost documents again.
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              RefReport is not just a product; it&apos;s a solution built from the ground up by officials, for officials. Our partnership with prestigious sports leagues like the Major Arena Soccer League (MASL) ensures that we’re always innovating to meet the needs of the modern sports industry. As a trusted partner, we’re committed to helping referees work more efficiently and focus on what matters most—officiating the game.
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              With RefReport, sports officials can manage everything from game schedules to report submissions in one place. It&apos;s fast, reliable, and secure—offering you peace of mind while making your reporting duties a breeze.
            </Typography>
          </Box>

          {/* CTA Section */}
          <Box
            sx={{
              my: 6,
              textAlign: 'center',
              p: 6,
              bgcolor: 'primary.light',
              borderRadius: 2,
              boxShadow: 3
            }}
        >
            <Typography variant="h4" gutterBottom>
              Join the RefReport Community Today!
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Make match reporting easy, accurate, and stress-free with RefReport. Get started now and see why officials across the country love us!
            </Typography>
            <Button target='_blank' href='mailto:contact@hooshmandenterprises.com?subject=RefReport%20Inquiry' variant="contained" color="secondary" size="large">
              Contact us
            </Button>
          </Box>
        </Container>
      </TopNavigation>
    </>
  )
}

export default Index
