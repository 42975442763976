import React, { useEffect, useState } from 'react'
import { Button, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { RequiredTextField, Title } from 'components/common'
import { resetPassword, validatePassword } from 'utils/auth'
import PasswordRequirements from 'components/common/PasswordRequirements'

import styles from 'components/ResetPassword.module.scss'

const ResetPassword: React.FC<{ oobCode: string | null }> = ({ oobCode }) => {
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorText, setErrorText] = useState<string>()

  useEffect(() => {
    handleChange()
  }, [password, confirmPassword])

  function handleChange () {
    setErrorText(undefined)
  }

  function handleSubmit (): void {
    if (!oobCode) return

    if (password !== confirmPassword) {
      setErrorText('Passwords do not match')
      return
    }

    if (!validatePassword(password)) {
      setErrorText('Password does not meet requirements')
      return
    }

    resetPassword(oobCode, password).then((value) => {
      navigate('/auth/sign-in?successReset=true')
    }).catch((e) => {
      setErrorText(e.toString())
      console.error(e)
    })
  }

  if (!oobCode) {
    return (
      <>
        <Title>Invalid password reset link</Title>
        <Link href="/">Back to sign in</Link>
      </>
    )
  }

  return (
    <section className={styles.container}>
      <h1>Reset your password</h1>
      <RequiredTextField label='New password' onChange={(event) => { setPassword(event.target.value) }} type='password' fullWidth/>
      <PasswordRequirements password={password} />
      <RequiredTextField label='Confirm new password' onChange={(event) => { setConfirmPassword(event.target.value) }} type='password' fullWidth/>
      <Button variant="contained" onClick={handleSubmit} fullWidth>Reset password</Button>
      {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
    </section>
  )
}
export default ResetPassword
