import { LocalizationProvider } from '@mui/x-date-pickers'
import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ConfirmProvider } from 'material-ui-confirm'
import { createTheme, ThemeProvider } from '@mui/material'

import AuthProvider from 'utils/providers/AuthProvider'
import PageTitleProvider from 'utils/providers/PageTitleProvider'
import ActiveLeagueProvider from 'utils/providers/ActiveLeagueProvider'
import { themeOptions } from 'default-theme'
import Root from 'components/common/Root'

import { authRoutes } from 'routes/auth'
import { maslRoutes } from 'routes/masl'
import { accountRoutes } from 'routes/account'

import Index from 'pages/index'
import Error from 'pages/error'
import Home from 'pages/undefined/home'
import Landing from 'pages/landing'
import FAQ from 'pages/faq'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<Root />}
      path="/"
      errorElement={<Error />}
    >
      <Route path='' element={<Index />} index />
      <Route path='/landing' element={<Landing />} index />
      <Route path='/undefined/home' element={<Home />} />
      <Route path='/faq' element={<FAQ />} />
      {authRoutes}
      {maslRoutes}
      {accountRoutes}
    </Route>
  )
)

const theme = createTheme(themeOptions)

const App: React.FC = () => {
  return (
    <PageTitleProvider>
      <AuthProvider>
        <ActiveLeagueProvider>
          <ThemeProvider theme={theme}>
            <ConfirmProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router} />
              </LocalizationProvider>
            </ConfirmProvider>
          </ThemeProvider>
        </ActiveLeagueProvider>
      </AuthProvider>
    </PageTitleProvider>
  )
}

export default App
