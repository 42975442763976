import React, { useState, type SyntheticEvent, useEffect } from 'react'
import {
  Autocomplete,
  type AutocompleteInputChangeReason,
  type AutocompleteChangeReason
} from '@mui/material'

import { RequiredTextField } from 'components/common'
import { type League } from 'utils/constants/constants'
import { useDebounce } from 'use-debounce'
import FuzzySearch from 'fuzzy-search'
import { type MASLCoach, MASL_COACHES } from 'utils/constants/masl'

interface CoachAutocompleteInputProps {
  defaultValue?: string
  onChange?: (
    event: SyntheticEvent<Element, Event>,
    value: string | Partial<MASLCoach>,
    reason: AutocompleteChangeReason
  ) => void
  disabled?: boolean
  error?: boolean
  league: League.MASL
  className?: string
}

export const CoachAutocompleteInput: React.FC<CoachAutocompleteInputProps> = ({ className, defaultValue, onChange, disabled, error, league }) => {
  const [options, setOptions] = useState<Array<Partial<MASLCoach>>>([])
  const [searchText, setSearchText] = useDebounce<string>(defaultValue ?? '', 200)

  function handleOnInputChange (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) {
    setSearchText(value)
  }

  useEffect(() => {
    if (searchText !== '') {
      const searcher = new FuzzySearch<MASLCoach>(MASL_COACHES, ['name', 'team'], { caseSensitive: false, sort: true })
      setOptions([
        ...searcher.search(searchText).slice(0, 5),
        { name: searchText, team: 'Unlisted Coach' }
      ])
    } else {
      setOptions([])
    }
  }, [searchText])

  return (
    <Autocomplete
      className={className}
      filterOptions={(x) => x}
      defaultValue={{ name: defaultValue }}
      onChange={onChange}
      onInputChange={handleOnInputChange}
      getOptionLabel={(option: string | Partial<MASLCoach>) => typeof option === 'string' ? option : (option?.name ?? '')}
      groupBy={(option) => option?.team ?? ''}
      options={options}
      renderInput={(params) => (
        <RequiredTextField {...params} label='Coach name' placeholder='Last name, First name' disabled={disabled} error={error} />
      )}
      disableClearable
      disabled={disabled}
      loading={false}
      freeSolo
    />
  )
}
