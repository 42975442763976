import { useApi } from 'utils/hooks/useApi'
import { post } from 'utils/api/api'
import { type LeagueAnnouncement, type League } from 'utils/constants/constants'

export async function getLeagueAnnouncements (
  league: League
): Promise<LeagueAnnouncement[]> {
  const response = await post<LeagueAnnouncement[]>(
    'https://getleagueannouncements-jdkif3ehcq-uc.a.run.app',
    { league }
  )
  return response
}

export const useLeagueAnnouncements = (league: League) => {
  const result = useApi(async () => {
    const announcements = await getLeagueAnnouncements(league)
    return { announcements }
  }, [league])

  return result
}
