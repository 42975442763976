import { useApi } from 'utils/hooks/useApi'
import { post } from 'utils/api/api'
import { type LeagueDocument, type League } from 'utils/constants/constants'

export async function getLeagueDocuments (
  league: League
): Promise<LeagueDocument[]> {
  const response = await post<LeagueDocument[]>(
    'https://getleaguedocuments-jdkif3ehcq-uc.a.run.app',
    { league }
  )
  return response
}

export const useLeagueDocuments = (league: League) => {
  const result = useApi(async () => {
    const documents = await getLeagueDocuments(league)
    return { documents }
  }, [league])

  return result
}
