import { type League } from 'utils/constants/constants'
import { type ListReportsResponse, post } from 'utils/api/api'
import { useAuthState } from 'utils/hooks/useAuthState'
import { useApi } from 'utils/hooks/useApi'
import { logSearch } from 'utils/analytics'
import { type DateFilter } from 'utils/constants/masl'

export async function listReports (
  league: League,
  userId: string,
  page: number,
  count = 10,
  searchQuery?: string,
  dateFilter?: DateFilter,
  season?: string | null
) {
  const response = await post<{
    reports: ListReportsResponse[]
    length: number
  }>('https://listreports-jdkif3ehcq-uc.a.run.app', {
    league,
    userId,
    page,
    count,
    searchQuery,
    dateFilter,
    season
  })
  return response
}

export const useLeagueReports = (league: League, page: number, count: number, searchQuery?: string, dateFilter?: DateFilter, season?: string | null) => {
  const { user } = useAuthState()
  const { data, error, loading } = useApi(async () => {
    logSearch(searchQuery)
    if (!user || !league) {
      return undefined
    }
    return await listReports(league, user?.id, page, count, searchQuery, dateFilter, season)
  }, [user?.id, league, page, count, searchQuery, dateFilter, season])

  return { reports: data?.reports, length: data?.length, error, loading }
}
