import React from 'react'

import { RequireAuth } from 'components/common/RequireAuth'
import DrawerNavigation from 'components/common/navigation/DrawerNavigation'
import PageTitle from 'components/common/PageTitle'
import HomeRoot from 'components/common/HomeRoot'
import Landing from 'pages/landing'
import { useAuthState } from 'utils/hooks/useAuthState'

const Home: React.FC = () => {
  const { user } = useAuthState()
  return (
    <RequireAuth ignoreAuthRedirect>
      <PageTitle title={'Home'} />
      <DrawerNavigation>
        <HomeRoot>
          {(user?.leagues.length ?? 0) > 0 && <Landing />}
        </HomeRoot>
      </DrawerNavigation>
    </RequireAuth>
  )
}

export default Home
