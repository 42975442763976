import { Link, Typography } from '@mui/material'
import TopNavigation from 'components/common/navigation/TopNavigation'
import React from 'react'

const FAQ: React.FC = () => {
  return (
    <>
      <TopNavigation>

        <Typography variant='h4'>Frequently asked questions</Typography>
        <p>Here are some frequently asked questions and their answers.</p>

        <div>
          <Typography variant='h5'>What is RefReport?</Typography>
          <section>
            <p>
              RefReport is a platform for referees to report on games they have officiated. Referees can use RefReport to submit reports on games they have officiated, view reports from other referees, and join leagues to compete with other referees.
            </p>
          </section>
        </div>

        <div>
          <Typography variant='h5'>How to sign up for RefReport.</Typography>
          <section>
            <p>
              To sign up for RefReport, click the sign up button on the home page or go to <Link target='_blank' href='https://refreport.net/auth/sign-up'>https://refreport.net/auth/sign-up</Link> in your browser. You will need to provide a name, email, and password. If you have a league code, you can enter that as well. If you do not have a league code, you can sign up without one.
            </p>
          </section>
        </div>

        <div>
          <Typography variant='h5'>How to join a league.</Typography>
          <section>
            <p>
              To join a league, you will need a league code. If you have a league code, you can enter it when signing up for RefReport. If you do not have a league code, you can sign up without one. If you have already signed up and want to join a league, you can join one by clicking on the &quot;<Link href='https://refreport.net/auth/join-league'>Join a League</Link>&quot; at the bottom of the menu once you have logged in enter a league code there.
            </p>
          </section>
        </div>

        <div>
          <Typography variant='h5'>How to complete a report.</Typography>
          <section>
            <p>
              To complete a report, click on the &quot;Edit&quot; button on the game report you want to complete. You will be taken to a page where you can fill out the report form. Once you have completed the form, click the &quot;Submit&quot; button at the bottom of the page to submit your report.
            </p>
          </section>
        </div>

        <div>
          <Typography variant='h5'>How to view reports from other referees.</Typography>
          <section>
            <p>
              To view reports from other referees, click on the &quot;View&quot; button on the report you want to view. You will be taken to a page where you can view the report. You can filter the reports by league, date, and other criteria to find the reports you are looking for.
            </p>
          </section>
        </div>
      </TopNavigation>
    </>
  )
}
export default FAQ
