import React, { type ReactNode, useEffect } from 'react'
import {
  Typography,
  Link
} from '@mui/material'

import { type League } from 'utils/constants/constants'
import { useAuthState } from 'utils/hooks/useAuthState'
import { useActiveLeague } from 'utils/hooks/useActiveLeague'

const HomeRoot: React.FC<{ league?: League, children?: ReactNode }> = ({ league, children }) => {
  const { user } = useAuthState()
  const [, setActiveLeague] = useActiveLeague()

  useEffect(() => {
    setActiveLeague(league)
  }, [league, setActiveLeague])

  return (
    <section style={{ height: '90%' }}>
      {user?.leagues.length === 0 && (
      <>
        <Typography variant='h4' color='dimgray' textAlign='center' marginTop={'1em'}>You are not a member of any leagues.</Typography>
        <Typography variant='body1' color='dimgray' textAlign='center' marginTop={'1em'}>You can <Link href='/auth/join-league'>join a league</Link> or ask your league administrator to add you to their league.</Typography>
      </>
      )}
      {children}
    </section>
  )
}

export default HomeRoot
