import React from 'react'

import IndexComponent from 'components/Index'

const Index: React.FC = () => {
  return (
    <IndexComponent />
  )
}

export default Index
