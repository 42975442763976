import {
  type League,
  type BaseSchema,
  type LeagueAnnouncement
} from 'utils/constants/constants'
import { fileToBase64String } from 'utils'
import { type User } from 'utils/providers/AuthProvider'

type Response<T> = T & { error?: string }

export async function post<T> (url: string, body: any): Promise<Response<T>> {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ data: body })
  })

  return (await response.json())?.result
}

export async function saveReport<T extends BaseSchema> (
  league: League,
  id: string,
  report: T,
  userId: string | undefined
): Promise<void> {
  await post('https://savereport-jdkif3ehcq-uc.a.run.app', {
    league,
    id,
    report,
    userId
  })
}

export async function getReport<T extends BaseSchema> (
  league: League,
  id: string,
  userId: string | undefined
) {
  if (!id || !userId) throw new Error('Error getting report.')

  const response = await post<T>('https://getreport-jdkif3ehcq-uc.a.run.app', {
    league,
    id,
    userId
  })
  return response as T
}

export interface ListReportsResponse {
  id: string
  league: string
  location: string
  homeTeam: string
  awayTeam: string
  editable: boolean
  gameNumber: string
  date: string
  gameTime: string
  season: string
}

export async function createReport<T extends BaseSchema> (
  league: League,
  report: Partial<T>
) {
  const response = await post<{ id: string }>(
    'https://createreport-jdkif3ehcq-uc.a.run.app',
    { league, report }
  )
  return response
}

export async function deleteReport (league: League, id: string, userId: string) {
  await post('https://deletereport-jdkif3ehcq-uc.a.run.app', {
    league,
    id,
    userId
  })
}

export async function addUser (userId: string, user: Partial<User>) {
  const response = await post<{ email: string, uid: string, newUser: boolean }>(
    'https://adduser-jdkif3ehcq-uc.a.run.app',
    { userId, user }
  )
  return response
}

export async function addUserWithCode (
  email: string,
  password: string,
  leagueCode?: string
) {
  const response = await post<{ error?: string }>(
    'https://adduserwithcode-jdkif3ehcq-uc.a.run.app',
    { email, password, leagueCode }
  )
  return response
}

export async function bulkCreateReports<T extends BaseSchema> (
  userId: string,
  league: League,
  reports: Array<Partial<T>>
) {
  const response = await post<{ ids: string[] }>(
    'https://bulkcreatereports-jdkif3ehcq-uc.a.run.app',
    { userId, league, reports }
  )
  return response
}

export async function removeUser (
  userId: string,
  removedUserId: string,
  league: League
) {
  const response = await post<any>(
    'https://removeuser-jdkif3ehcq-uc.a.run.app',
    { userId, removedUserId, league }
  )
  return response
}

export async function uploadReportImage (
  imageName: string,
  file: Blob | File,
  league: League,
  reportId: string
): Promise<string> {
  const imageBytes64Str = await fileToBase64String(file)
  return await post('https://uploadreportimage-jdkif3ehcq-uc.a.run.app', {
    imageName,
    imageBytes64Str,
    league,
    reportId
  })
}

export async function deleteLeagueDocument (
  league: string,
  name: string,
  uri: string
) {
  const response = await post(
    'https://deleteleaguedocuments-jdkif3ehcq-uc.a.run.app',
    { league, name, uri }
  )
  return response
}

export async function addLeagueDocument (
  league: string,
  name: string,
  uri: string
) {
  const response = await post(
    'https://addleaguedocuments-jdkif3ehcq-uc.a.run.app',
    { league, name, uri }
  )
  return response
}

export async function deleteLeagueAnnouncement (league: string, id: string) {
  const response = await post(
    'https://deleteleagueannouncement-jdkif3ehcq-uc.a.run.app',
    { league, id }
  )
  return response
}

export async function addLeagueAnnouncement (
  league: string,
  announcement: LeagueAnnouncement
) {
  const response = await post(
    'https://addleagueannouncement-jdkif3ehcq-uc.a.run.app',
    { league, announcement }
  )
  return response
}

export async function deleteUserData (userId: string) {
  await post('https://deleteuserdata-jdkif3ehcq-uc.a.run.app', { userId })
}

export async function addLeagueSeason (league: League, season: string) {
  await post('https://addleagueseason-jdkif3ehcq-uc.a.run.app', {
    league,
    season
  })
}
