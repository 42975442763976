import { type League } from 'utils/constants/constants'
import { useApi } from 'utils/hooks/useApi'
import { post } from 'utils/api/api'
import { type User } from 'utils/providers/AuthProvider'

export async function getLeagueUsers (league: League) {
  const response = await post<{ users: User[] }>(
    'https://getleagueusers-jdkif3ehcq-uc.a.run.app',
    { league }
  )
  return response
}

export const useLeagueUsers = (league: League) => {
  const { data, loading, error, trigger } = useApi(async () => {
    return await getLeagueUsers(league)
  }, [league])

  return { data, loading, error, trigger }
}
