import { post } from 'utils/api/api'
import { type League } from 'utils/constants/constants'
import { useApi } from 'utils/hooks/useApi'

export async function getLeague<T> (league: League): Promise<T> {
  const response = await post<T>('https://getleague-jdkif3ehcq-uc.a.run.app', {
    league
  })
  return response
}

export const useLeague = <T>(league: League): { data: T | undefined, error?: Error, loading: boolean } => {
  const { data, error, loading } = useApi(async () => {
    if (!league) {
      return undefined
    }
    return await getLeague<T>(league)
  }, [league])

  return { data, error, loading }
}
