import React, { memo } from 'react'

import { useAuthState } from 'utils/hooks/useAuthState'
import { Typography } from '@mui/material'
import { useActiveLeague } from 'utils/hooks/useActiveLeague'
import { LinkButton } from 'components/common'

import styles from 'components/Landing.module.scss'

const Landing: React.FC = memo(() => {
  const { user } = useAuthState()
  const [activeLeague, setActiveLeague] = useActiveLeague()

  return (
    <main>
      <article className={styles.content}>
        <section className={styles.leagueGrid}>
          <div className={styles.leagueHeaderRow}>
            <Typography variant='h5'>Leagues</Typography>
          </div>
          {activeLeague && (
            <div className={`${styles.leagueRow as string} ${styles.dark as string}`}>
              <Typography variant='subtitle1'>{activeLeague.toLocaleUpperCase()}</Typography>
              <LinkButton className={styles.link} to={`/${activeLeague}/home`} variant='contained'>Open</LinkButton>
            </div>
          )}
          {user?.leagues.sort().filter(league => league !== activeLeague).map((league, index) => (
            <div
              key={league}
              className={`${styles.leagueRow as string} ${((index + (activeLeague ? 1 : 0)) % 2 === 0 && styles.dark) as string}`}
            >
              <Typography variant='subtitle1'>{league.toLocaleUpperCase()}</Typography>
              <LinkButton
                className={styles.link}
                to={`/${league}/home`}
                variant='contained'
                onBeforeNavigate={() => { setActiveLeague(league) }}
              >
                Open
              </LinkButton>
            </div>
          ))}
        </section>
      </article>
    </main>
  )
})

export default Landing
