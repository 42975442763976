import { Checkbox, Divider, FormControl, FormControlLabel, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'

import { signUpUser, validatePassword } from 'utils/auth'
import { RequiredTextField, LinkButton, Section, BasicTextField } from 'components/common'
import PasswordRequirements from 'components/common/PasswordRequirements'
import TopNavigation from 'components/common/navigation/TopNavigation'
import PageTitle from 'components/common/PageTitle'
import { useRedirectToHome } from 'utils/hooks/useRedirectToHome'

import styles from './sign-up.module.scss'

const SignUp: React.FC = () => {
  const [params] = useSearchParams()
  const redirect = useRedirectToHome()
  const code = useMemo(() => params.get('code'), [params])

  const [displayName, setDisplayName] = useState<string>('')
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [leagueCode, setLeagueCode] = useState<string>('')
  const [errorText, setErrorText] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [addLeagueCode, setAddLeagueCode] = useState(false)

  useEffect(() => {
    if (code) {
      setAddLeagueCode(true)
    } else {
      setAddLeagueCode(false)
    }
  }, [code])

  function handleUserSignUp () {
    if (!email || !password || (addLeagueCode && !code && !leagueCode)) return

    if (password !== confirmPassword) {
      setErrorText('Passwords do not match')
      return
    }

    if (!validatePassword(password)) {
      setErrorText('Password does not meet requirements')
      return
    }

    setLoading(true)
    signUpUser(email.toLowerCase().trim(), password, displayName, code ?? leagueCode)
      .then(() => {
        void redirect({ replace: true })
        setLoading(false)
      }).catch((e: Error): void => {
        setErrorText(e?.message?.replace('Firebase: ', '')?.replace('-', ' ')?.replace('auth/', ''))
        setLoading(false)
      })
  }

  return (
    <>
      <PageTitle title='Sign up' />
      <TopNavigation>
        <section className={styles.container}>
          <Typography variant='h5'>Sign up for RefReport</Typography>
          <form>
            <FormControl className={styles.container}>
              <RequiredTextField label='Your name' type='text' onChange={(event) => { setDisplayName(event.target.value) }} error={!displayName} />
              <RequiredTextField label='E-mail' type='email' onChange={(event) => { setEmail(event.target.value) }} error={!email} />
              <RequiredTextField label='Password' type='password' onChange={(event) => { setPassword(event.target.value) }} error={!password} />
              <PasswordRequirements password={password} />
              <RequiredTextField label='Confirm password' type='password' onChange={(event) => { setConfirmPassword(event.target.value) }} fullWidth error={!confirmPassword} />
              <FormControlLabel control={<Checkbox value={addLeagueCode} checked={addLeagueCode} onChange={(event) => { setAddLeagueCode(event.target.checked) }} disabled={!!code} />} label='Sign up using a League Code' />
              {(addLeagueCode) && <BasicTextField autoComplete='off' value={code} label='League code' onChange={(event) => { setLeagueCode(event.target.value) }} disabled={!!code} error={addLeagueCode && !code && !leagueCode} />}
            </FormControl>
          </form>
          <LoadingButton variant="contained" onClick={handleUserSignUp} loading={loading}>Sign Up</LoadingButton>
          {errorText && <p style={{ color: 'red' }}>Error signing up: {errorText}</p>}
          <Divider />
          <Section>
            <LinkButton to='/auth/sign-in'>Go to sign in</LinkButton>
            <Divider orientation='vertical' flexItem />
            <LinkButton to='/auth/reset'>Reset password</LinkButton>
          </Section>
        </section>
      </TopNavigation>
    </>
  )
}

export default SignUp
