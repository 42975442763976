import React from 'react'

import { RequireAuth } from 'components/common/RequireAuth'
import PageTitle from 'components/common/PageTitle'
import DrawerNavigation from 'components/common/navigation/DrawerNavigation'
import MASLNavigationMenu from 'components/masl/MASLNavigationMenu'
import { type League } from 'utils/constants/constants'
import LeagueAnnouncements from 'components/common/LeagueAnnouncements'

interface AnnouncementsProps {
  league: League.MASL | League.M2 | League.M3
}

const Announcements: React.FC<AnnouncementsProps> = ({ league }) => {
  return (
    <RequireAuth>
      <PageTitle title='Announcements' />
      <DrawerNavigation menu={<MASLNavigationMenu league={league} />}>
        <LeagueAnnouncements league={league} />
      </DrawerNavigation>
    </RequireAuth>
  )
}
export default Announcements
