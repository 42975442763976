import React, { useMemo } from 'react'
import { Button, Autocomplete } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { useConfirm } from 'material-ui-confirm'

import { type MASLTeam, type Review, type MASLReportSchema, periods, Initiator, GameType, extraTimePeriods } from 'utils/constants/masl'
import { RequiredTextField } from 'components/common'
import { validate } from 'utils'
import { VRAutocompleteInput } from 'components/masl/inputs/VRAutocompleteInput'
import ClockTimeInput from 'components/common/ClockTimeInput'

import style from './MASLReviews.module.scss'

interface ReviewFragmentProps {
  homeTeam: MASLTeam | string
  awayTeam: MASLTeam | string
  onReviewChanged: (review: Review) => void
  onReviewRemoved: (id: string) => void
  review: Review
  gameType?: GameType
}

const ReviewFragment: React.FC<ReviewFragmentProps> = ({ homeTeam, awayTeam, gameType, review, onReviewChanged, onReviewRemoved }) => {
  const periodOptions = useMemo(() => gameType === GameType.EXTRA_TIME_PERIOD ? extraTimePeriods : periods, [gameType])

  return (
    <section
      key={review.id}
      className={style.tableRow}
    >
      <div className={style.tableCell}>
        <Autocomplete
          value={review.initiator}
          style={{ minWidth: 200 }}
          onChange={(event, value) => { onReviewChanged({ ...review, initiator: value }) }}
          options={[Initiator.COACH_CHALLENGE, Initiator.REFEREE]}
          renderInput={(params) => (
            <RequiredTextField {...params} label='Initiator' error={!validate(review.initiator)} />
          )}
          disableClearable
        />
      </div>
      <div className={style.tableCell}>
        <Autocomplete
          value={review.team}
          style={{ minWidth: 200 }}
          onChange={(event, value) => { onReviewChanged({ ...review, team: value }) }}
          options={[homeTeam, awayTeam, 'Referee']}
          renderInput={(params) => (
            <RequiredTextField {...params} label='Team' error={!validate(review.team)} />
          )}
          disableClearable
        />
      </div>
      <div className={style.tableCell}>
        <Autocomplete
          style={{ minWidth: 200 }}
          value={review.period}
          onChange={(event, value) => {
            onReviewChanged({ ...review, period: value })
          }}
          options={periodOptions}
          renderInput={(params) => (
            <RequiredTextField {...params} label='Period' error={!validate(review.period)}/>
          )}
          disableClearable
        />
      </div>
      <div className={style.tableCell}>
        <ClockTimeInput
          style={{ minWidth: 200 }}
          value={review.time}
          error={!validate(review.time)}
          onChange={(e) => {
            onReviewChanged({ ...review, time: e.target.value })
          }}
        />
      </div>
      <div className={style.tableCell}>
        <VRAutocompleteInput
          value={{ description: review.category ?? '', code: review.code ?? '' }}
          style={{ minWidth: 300 }}
          onChange={(event, value) => {
            onReviewChanged({ ...review, category: value.description, code: value.code })
          }}
          error={!validate(review.category) || !validate(review.code)}
        />
      </div>
      <div className={style.tableCell}>
        <Autocomplete
          value={review.result}
          style={{ minWidth: 250 }}
          onChange={(event, value) => {
            onReviewChanged({ ...review, result: value })
          }}
          options={['Decision Changed', 'Decision Upheld', 'Equipment Malfunction']}
          renderInput={(params) => (
            <RequiredTextField {...params} label='Review Result' error={!validate(review.result)}/>
          )}
          disableClearable
        />
      </div>
      <div style={{ width: '100%' }}><Button color='error' onClick={() => { onReviewRemoved(review.id) } }>Delete</Button></div>
    </section>
  )
}

interface MASLReviewsProps {
  reviews: Review[] | undefined
  homeTeam: MASLTeam | string
  awayTeam: MASLTeam | string
  onStateChange: (key: keyof MASLReportSchema, value: Review[]) => void
  gameType?: GameType
}

const MASLReviews: React.FC<MASLReviewsProps> = ({ reviews, homeTeam, awayTeam, gameType, onStateChange }) => {
  const confirmDialog = useConfirm()

  function createReview () {
    onStateChange('reviews', [...(reviews ?? []), { id: uuid() }])
  }

  function updateReview (review: Review): void {
    const editedReviews = reviews ?? []
    const index = editedReviews?.findIndex(r => r.id === review.id)
    editedReviews[index] = review
    onStateChange('reviews', editedReviews)
  }

  function deleteReview (id: string): void {
    confirmDialog({
      title: 'Delete review',
      description: <>Do you want to delete this review?</>,
      confirmationText: 'Delete',
      confirmationButtonProps: { color: 'error' }
    }).then(() => {
      onStateChange('reviews', [...(reviews ?? []).filter(review => review.id !== id)])
    }).catch(() => {})
  }

  return (
    <>
      {(reviews ?? []).length > 0 && (
        <section>
          {(reviews ?? []).map((review: Review) => (
            <ReviewFragment
              key={review.id}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              onReviewChanged={updateReview}
              onReviewRemoved={deleteReview}
              review={review}
              gameType={gameType}
            />
          ))}
        </section>
      )}
      <Button style={{ marginTop: 8 }} onClick={createReview} fullWidth>Add Video Review</Button>
    </>
  )
}
export default MASLReviews
